import * as React from "react";
import CustomerList from "../components/CustomerList";
import Layout from "../components/Layout";

const Clients = () => (
  <Layout
    title="Nos clients"
    metaTitle="Nos clients"
    description="Parcourez nos études de cas et découvrez comment nous avons accompagné nos clients à réaliser leurs projets"
  >
    <p className="mt-main">
      Chez Donut Panic, nous instaurons une relation basée sur l’empathie et la
      convivialité, valeurs centrales pour notre entreprise. Nous avons
      travaillé avec plus de 50 marques. Découvrez le détail de la méthode
      employée pour développer leurs sites. Toutes nos réalisations peuvent vous
      être expliquées de vive voix sur simple prise de rendez-vous. Certaines
      sections nécessitent des informations vous concernant. N’hésitez pas à
      contacter l’équipe en cas de questions sur l’un de nos clients et sur la
      façon dont nous avons traité son cas et ciblé ses besoins. Cliquez sur un
      des logos ci-dessous pour accéder à l’UX Case lui correspondant.
    </p>
    <CustomerList fullList />
  </Layout>
);

export default Clients;
